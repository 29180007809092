@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  @apply w-full h-full;
  overflow: hidden;
}
body {
  @apply w-full h-full bg-black;
  @apply text-white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#root{
  @apply w-full h-full;
}
